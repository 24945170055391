import React, { useContext } from "react";
import Modal from "react-modal";
import { ThemeContext } from "../context/themeContext";

Modal.setAppElement(document.getElementById("root"));

const CommonModal = ({
  isOpen,
  closeModal,
  children,
  width,
  maxWidth,
  height,
  overflowY = true,
  overlayWidth,
  isChartModal,
}) => {
  const { theme } = useContext(ThemeContext);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      borderRadius: "12px",
      overflowY: overflowY ? "auto" : "hidden",
      width: width,
      maxWidth: maxWidth,
      height: height,
      backgroundColor: "hsl(var(--background))",
      border: isChartModal
        ? theme === "dark"
          ? "1px solid hsl(var(--borderBottom))"
          : "1px solid hsl(var(--borderBottom))"
        : theme === "dark"
        ? "1px solid #333"
        : "1px solid #f1f1f1",
    },
    overlay: {
      backgroundColor: isChartModal ? "unset !important" : "rgba(0, 0, 0, 0.5)",
      backdropFilter: isChartModal ? "unset" : "blur(5px)",
      zIndex: "99",
      width: overlayWidth,
    },
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        {children}
      </Modal>
    </>
  );
};

export default CommonModal;
