import React, { useEffect, useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ThemeContext } from "../context/themeContext";
import {
  fetchAsinWatchlist,
  addSingleAsin,
  deleteSingleAsin,
  exportAsins,
  searchAsinWatchlist,
  fetchPriceAlertInfo,
} from "../redux/slices/asinWatchlistSlice";

const AsinManagementModal = ({ onClose, onShowAsinsModal }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [asinInput, setAsinInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const listContainerRef = useRef(null);

  const {
    watchlist,
    totalPages,
    totalItems,
    status,
    asinsLocked,
    trackedAsins,
    asinTokens,
  } = useSelector((state) => state.asinWatchlist);

  // Filter watchlist based on search query
  const filteredWatchlist =
    watchlist?.filter(
      (item) =>
        item.asin.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.title?.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  // Calculate pagination values based on filtered results
  const filteredTotalItems = filteredWatchlist.length;
  const filteredTotalPages = Math.ceil(filteredTotalItems / limit);
  const currentPageItems = filteredWatchlist.slice(
    page * limit,
    (page + 1) * limit
  );

  // Format numbers with thousands separator
  const formatNumber = (num) => {
    return num.toLocaleString("de-DE");
  };

  useEffect(() => {
    loadWatchlist();
    dispatch(fetchPriceAlertInfo());
    return () => setIsLoading(true);
  }, []); // Only load once when component mounts

  // Reset page when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // Reset page when limit changes
  useEffect(() => {
    setPage(0);
  }, [limit]);

  const loadWatchlist = async () => {
    setIsLoading(true);
    try {
      const result = await dispatch(
        fetchAsinWatchlist({
          page: 0,
          limit: 10000, // Set a high limit to get all ASINs
        })
      ).unwrap();
    } catch (error) {
      console.error("Error loading watchlist:", error);
      toast.error(error.message || "Failed to load ASINs");
    }
    setIsLoading(false);
  };

  const handleAddAsin = async () => {
    if (!asinInput.trim()) return;

    setIsLoading(true);
    try {
      const result = await dispatch(addSingleAsin(asinInput.trim())).unwrap();
      console.log("Add ASIN result:", result);

      if (result.status === "authorized") {
        setAsinInput("");
        toast.success("ASIN added successfully");
        // Refresh the watchlist immediately after adding
        await dispatch(fetchAsinWatchlist({ page: 0, limit: 10000 })).unwrap();
      } else {
        toast.error(result.message || "Failed to add ASIN");
      }
    } catch (error) {
      console.error("Error adding ASIN:", error);
      toast.error(error.message || "Failed to add ASIN");
    }
    setIsLoading(false);
  };

  const handleDeleteAsin = async (asin) => {
    setIsLoading(true);
    try {
      const result = await dispatch(deleteSingleAsin(asin)).unwrap();
      if (result.status === "authorized") {
        toast.success("ASIN deleted successfully");
        loadWatchlist();
      } else {
        toast.error(result.message || "Failed to delete ASIN");
      }
    } catch (error) {
      console.error("Error deleting ASIN:", error);
      toast.error(error.message || "Failed to delete ASIN");
    }
    setIsLoading(false);
  };

  const handleExport = async () => {
    try {
      await dispatch(exportAsins()).unwrap();
      toast.success("ASINs exported successfully");
    } catch (error) {
      toast.error("Failed to export ASINs");
    }
  };

  // Update search handling
  const handleSearch = async (query) => {
    setSearchQuery(query);

    // Clear existing timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // If query is empty, load full watchlist
    if (!query.trim()) {
      loadWatchlist();
      return;
    }

    // Set new timeout for search
    const timeoutId = setTimeout(async () => {
      setIsLoading(true);
      try {
        await dispatch(searchAsinWatchlist(query.trim())).unwrap();
      } catch (error) {
        console.error("Error searching watchlist:", error);
        toast.error(error.message || "Failed to search ASINs");
      }
      setIsLoading(false);
    }, 300); // 300ms debounce

    setSearchTimeout(timeoutId);
  };

  const renderPagination = (totalPages) => {
    const pages = [];
    const maxVisiblePages = 5;

    // Add back button
    pages.push(
      <button
        key="prev"
        onClick={() => setPage(Math.max(0, page - 1))}
        className="dark:bg-[linear-gradient(180deg,_rgba(38,_247,_184,_0.1)_0%,_rgba(12,_216,_155,_0.1)_100%)] text-blue-400 dark:text-[#27FFBA] hover:text-black cursor-pointer px-2 py-1 rounded mr-2 text-xs border dark:border-none font-bold"
        disabled={page === 0}
        aria-label="Previous page">
        <i className="bx bx-chevron-left font-bold"></i>
      </button>
    );

    // Always show first page
    pages.push(
      <button
        key={0}
        onClick={() => setPage(0)}
        className={`px-2 py-1 text-xs font-bold ${
          page === 0
            ? "dark:text-[#27FFBA] text-blue-400"
            : "text-[#5F5E5EE5] hover:text-black"
        }`}
        aria-label="Go to page 1">
        1
      </button>
    );

    // Calculate the range of visible pages
    let start = Math.max(1, page - Math.floor((maxVisiblePages - 2) / 2));
    let end = Math.min(start + maxVisiblePages - 3, totalPages - 2);

    // Adjust start if end is at its maximum
    if (end === totalPages - 2) {
      start = Math.max(1, end - (maxVisiblePages - 3));
    }

    // Add ellipsis after first page if needed
    if (start > 1) {
      pages.push(
        <span
          key="ellipsis1"
          className="text-[#5F5E5EE5] font-bold px-1 text-xs">
          ...
        </span>
      );
    }

    // Add middle pages
    for (let i = start; i <= end; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setPage(i)}
          className={`px-2 py-1 text-xs font-bold ${
            page === i
              ? "dark:text-[#27FFBA] text-blue-400"
              : "text-[#5F5E5EE5] hover:text-black"
          }`}
          aria-label={`Go to page ${i + 1}`}>
          {i + 1}
        </button>
      );
    }

    // Add ellipsis before last page if needed
    if (end < totalPages - 2) {
      pages.push(
        <span key="ellipsis2" className="text-[#5F5E5EE5] px-1 text-xs">
          ...
        </span>
      );
    }

    // Always show last page if there is more than one page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages - 1}
          onClick={() => setPage(totalPages - 1)}
          className={`px-2 py-1 text-xs font-bold ${
            page === totalPages - 1
              ? "dark:text-[#27FFBA] text-blue-400"
              : "text-[#5F5E5EE5] hover:text-black"
          }`}
          aria-label={`Go to page ${totalPages}`}>
          {totalPages}
        </button>
      );
    }

    // Add next button
    pages.push(
      <button
        key="next"
        onClick={() => setPage(Math.min(page + 1, totalPages - 1))}
        className="dark:bg-[linear-gradient(180deg,_rgba(38,_247,_184,_0.1)_0%,_rgba(12,_216,_155,_0.1)_100%)] text-blue-400 dark:text-[#27FFBA] hover:text-black cursor-pointer px-2 py-1 rounded ml-2 text-xs font-bold border dark:border-none"
        disabled={page === totalPages - 1}
        aria-label="Next page">
        <i className="bx bx-chevron-right font-bold"></i>
      </button>
    );

    return pages;
  };

  return (
    <div className="bg-bgColor relative w-full h-fit max-w-[1400px] rounded-lg shadow-lg">
      <button
        onClick={onClose}
        className="text-primary h-fit w-fit leading-none bg-[linear-gradient(180deg,_rgba(38,_247,_184,_0.1)_0%,_rgba(12,_216,_155,_0.1)_100%)] dark:bg-[#27FFBA33] hover:text-white p-0 absolute top-2 right-2"
        aria-label="Close modal">
        <i className="bx bx-x text-2xl p-0 h-fit w-fit leading-none"></i>
        <span className="sr-only">Close</span>
      </button>
      <div className="py-8 lg:py-10 lg:px-6 xl:px-10 px-4">
        {/* Header */}
        <div className="flex flex-col lg:flex-row  mx-auto justify-between gap-4 items-start lg:items-center mb-4">
          <div className="max-w-full xl:max-w-[50%] lg:max-w-[40%] flex flex-col">
            <div className="flex flex-wrap gap-3 my-auto">
              <h2 className="text-xl lg:text-2xl font-semibold">
                <span className="text-primary">Asins</span>{" "}
                <span className="text-textTitle">verwalten</span>
              </h2>
              <div className="flex gap-2">
                <p className="text-textPrimary text-sm my-auto">
                  {formatNumber(trackedAsins)} / {formatNumber(asinTokens)}{" "}
                </p>
                <span className="bg-[linear-gradient(216.87deg,_rgba(96,_116,_151,_0.18)_-34.6%,_rgba(161,_195,_253,_0.18)_48.31%,_rgba(96,_116,_151,_0.18)_121.93%)] dark:bg-blue-900 text-[10px]  h-fit my-auto text-blue-600 dark:text-blue-300 px-2 py-[2px] rounded">
                  {formatNumber(asinsLocked)} Asins locked
                </span>
              </div>
            </div>

            <p className="text-[#5F5E5EE5] text-sm mt-2">
              Füge neue Asins hinzu, bearbeite diese oder lösche bestehende
              Asins.
            </p>
          </div>

          {/* Search and Add Section */}
          <div className="flex flex-col lg:flex-row gap-3 mt-4 lg:mt-0 my-auto flex-1 justify-end w-full lg:w-auto">
            <div className="relative w-full flex-1 lg:max-w-[180px] order-2 lg:order-1">
              <i className="bx bx-search absolute text-sm left-3 top-1/2 transform -translate-y-1/2 text-textPrimary"></i>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Suche eine Asin"
                className="w-full my-auto h-8 placeholder:text-textPrimary dark:placeholder:text-[#535355] placeholder:text-sm bg-background text-textTitle pl-10 px-4 rounded border border-borderBottom focus:outline-none focus:border-primary"
              />
            </div>
            <div className="flex gap-2 w-full lg:w-auto items-center order-1 lg:order-2">
              <input
                type="text"
                value={asinInput}
                onChange={(e) => setAsinInput(e.target.value)}
                placeholder="Asin hinzufügen"
                className="w-full lg:max-w-[140px] flex-1 text-sm text-center my-auto h-8 placeholder:text-textPrimary dark:placeholder:text-[#535355] placeholder:text-sm placeholder:text-center bg-background text-textTitle px-4 rounded border border-borderBottom focus:outline-none focus:border-primary"
              />
              <button
                onClick={handleAddAsin}
                className="bg-[linear-gradient(180deg,_#9DBAF8_0%,_#366CF6_100%)] dark:bg-[linear-gradient(180deg,_rgba(38,_247,_184,_0.1)_0%,_rgba(12,_216,_155,_0.1)_100%)] hover:opacity-90 dark:hover:bg-[#27FFBA33] text-white h-6 lg:h-8 w-8 rounded-md flex items-center justify-center"
                disabled={isLoading}
                aria-label="Add ASIN">
                <svg
                  className="w-4 h-4 text-lg font-bold text-white  dark:text-[#27FFBA]"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.40163 11V0H6.59024V11H4.40163ZM0 6.59024V4.40163H11V6.59024H0Z"
                    fill="currentColor"
                  />
                </svg>

                <span className="sr-only">Add ASIN</span>
              </button>
              <button
                onClick={() => {
                  onClose();
                  onShowAsinsModal();
                }}
                className="bg-[#a1c3fd49] dark:bg-[#A1C3FD2E] dark:hover:bg-[#a1c3fd60] hover:bg-[#a1c3fd9a] text-white  h-6 lg:h-8 w-8 rounded-md flex items-center justify-center"
                aria-label="Import ASINs File">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 dark:text-[#A1C3FD] text-[#366CF6]">
                  <path
                    d="M7 14C3.68629 14 1 11.3137 1 8M13 8C13 9.8948 12.1217 11.5844 10.75 12.684"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M7 8V1M7 1L10 3.1M7 1L4 3.1"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="sr-only">Import ASINs File</span>
              </button>
              <button
                onClick={handleExport}
                className="dark:bg-[#9D2CFF38] bg-[#a1c3fd49] hover:bg-[#a1c3fd9a]  dark:hover:bg-[#4d1f76bc] text-white h-6 lg:h-8 w-8 rounded-md flex items-center justify-center"
                aria-label="Export ASINs">
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 dark:text-[#8A59B4] text-[#366CF6]">
                  <path
                    d="M0.929817 7.89324L0.857506 7.96448L0.929826 8.03572L4.34441 11.399L4.41455 11.4681L4.48472 11.399L5.15105 10.7435L5.22344 10.6723L5.15112 10.601L3.0478 8.52783H12.2871H12.3871V8.42783V7.50019V7.40019H12.2871H3.04787L5.15109 5.328L5.2234 5.25676L5.15109 5.18553L4.48476 4.52902L4.41458 4.45987L4.34439 4.52902L0.929817 7.89324ZM16.6566 4.0424L16.6564 4.04226L13.9091 1.33642C13.9091 1.33641 13.9091 1.33641 13.9091 1.3364C13.6273 1.05872 13.2373 0.9 12.8399 0.9H6.6328H6.5328V1V6.57143V6.67143H6.6328H7.57526H7.67526V6.57143V2.02857H12.1876V5.64286V5.74286H12.2876H15.9575V12.9714H7.67527V9.35715V9.25714H7.57527H6.63278H6.53278V9.35715V14V14.1H6.63278H17H17.1V14V5.09871C17.1 4.70586 16.9383 4.32098 16.6566 4.0424ZM13.3301 4.61429V2.35969L15.6185 4.61429H13.3301Z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="0.2"
                  />
                </svg>

                <span className="sr-only">Export ASINs</span>
              </button>
            </div>
          </div>
        </div>

        {/* ASINs List */}
        <div
          ref={listContainerRef}
          className="space-y-[12px] overflow-x-auto overflow-y-auto max-h-[400px]">
          {isLoading ? (
            Array.from({ length: searchQuery ? 1 : 5 }).map((_, i) => (
              <div
                key={`skeleton-${i}`}
                className="bg-background rounded-lg px-4 py-2 flex flex-col sm:flex-row items-start lg:items-center justify-between border border-borderBottom">
                <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4 w-full lg:w-auto">
                  <div className="w-full flex space-x-6 lg:w-auto">
                    <Skeleton
                      width={100}
                      height={100}
                      baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                      highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                    />
                    <div className="w-fit lg:w-auto">
                      <Skeleton
                        width={300}
                        height={20}
                        baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                        highlightColor={
                          theme === "dark" ? "#1a1a1a" : "#ffffff"
                        }
                      />
                      <div className="flex flex-col sm:flex-row flex-wrap gap-2 sm:gap-4 lg:gap-8 mt-2">
                        {Array.from({ length: 3 }).map((_, idx) => (
                          <div key={idx}>
                            <Skeleton
                              width={60}
                              height={12}
                              baseColor={
                                theme === "dark" ? "#0e0e12" : "#f9f9f9"
                              }
                              highlightColor={
                                theme === "dark" ? "#1a1a1a" : "#ffffff"
                              }
                            />
                            <Skeleton
                              width={80}
                              height={24}
                              baseColor={
                                theme === "dark" ? "#0e0e12" : "#f9f9f9"
                              }
                              highlightColor={
                                theme === "dark" ? "#1a1a1a" : "#ffffff"
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-col items-center sm:items-end justify-end sm:justify-between gap-2 mt-4 sm:mt-0 w-full sm:w-auto sm:h-full sm:py-2">
                  <Skeleton
                    width={32}
                    height={32}
                    circle
                    baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                    highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                  />
                  <Skeleton
                    width={32}
                    height={32}
                    circle
                    baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                    highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                  />
                </div>
              </div>
            ))
          ) : currentPageItems.length > 0 ? (
            currentPageItems.map((item) => (
              <div
                key={item.asin}
                className="dark:bg-bgColor bg-white rounded-lg px-4 py-2 flex flex-col sm:flex-row items-start lg:items-center justify-between border-[0.5px] border-borderBottom">
                <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2 w-full">
                  <div className="w-full flex space-x-4 lg:space-x-6">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="w-[80px] my-auto h-[80px] lg:w-[100px] lg:h-[100px] object-contain bg-white rounded"
                    />
                    <div className="flex-1 lg:flex-none min-w-0 max-w-full">
                      <h3
                        className="text-textTitle font-medium text-sm lg:text-base truncate max-w-[500px] lg:max-w-[600px]"
                        title={item.title || "Nicht verfügbar"}>
                        {item.title || "Nicht verfügbar"}
                      </h3>
                      <div className="flex flex-col sm:flex-row flex-wrap gap-2 sm:gap-4 lg:gap-8 mt-2 text-xs lg:text-sm text-textPrimary">
                        <div className="min-w-[100px]">
                          <span className="text-[10px]">ASIN</span>
                          <p className="text-textTitle text-center bg-white/5 py-[2px] px-2">
                            {item.asin}
                          </p>
                        </div>
                        <div className="min-w-[100px]">
                          <span className="text-[10px]">TRACKING SEIT</span>
                          <p className="text-textTitle text-center bg-white/5 py-[2px] px-2">
                            {item.tracked_since}
                          </p>
                        </div>
                        <div className="min-w-[100px]">
                          <span className="text-[10px]">LÖSCHBAR AB</span>
                          <p className="text-blue-400 text-center bg-[linear-gradient(216.87deg,_rgba(96,_116,_151,_0.18)_-34.6%,_rgba(161,_195,_253,_0.18)_48.31%,_rgba(96,_116,_151,_0.18)_121.93%)] py-[2px] px-2 rounded-sm">
                            {item.deletable_date}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-col items-center sm:items-end justify-end sm:justify-between gap-4 lg:gap-1 mt-4 sm:mt-0 w-full sm:w-auto sm:h-full sm:py-2">
                  <button
                    onClick={() =>
                      window.open(`https://amazon.de/dp/${item.asin}`, "_blank")
                    }
                    className="dark:text-[#E5E5E580] text-[#00000043] dark:hover:text-[#e5e5e5c7] hover:text-black lg:p-2 sm:p-0 h-fit w-fit text-xs"
                    aria-label={`Open ${item.title} on Amazon`}>
                    <i className="bx bx-link-external"></i>
                    <span className="sr-only">Open on Amazon</span>
                  </button>
                  <button
                    onClick={() => handleDeleteAsin(item.asin)}
                    className="text-red-500 hover:text-red-400 lg:p-1 sm:p-0  h-fit w-fit text-xs"
                    aria-label={`Delete ${item.title}`}
                    disabled={isLoading || item.locked}>
                    <i className="bx bx-trash bg-red-200 dark:bg-transparent p-[2px] hover:bg-red-300 h "></i>
                    <span className="sr-only">Delete</span>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-8">
              <p className="text-textTitle text-lg">
                {searchQuery
                  ? "Keine Ergebnisse gefunden"
                  : "Keine ASINs in der Watchlist"}
              </p>
              <p className="text-textSecondary text-sm mt-2">
                {searchQuery
                  ? "Versuchen Sie es mit einem anderen Suchbegriff"
                  : "Fügen Sie ASINs hinzu, um sie zu überwachen"}
              </p>
            </div>
          )}
        </div>

        {/* Pagination */}
        <div className="flex flex-col lg:flex-row items-center justify-between gap-4 mt-6 relative">
          <div className="lg:w-32 hidden lg:block"></div>
          <div className="flex flex-wrap justify-center items-center gap-1 flex-1 py-2 px-4 rounded">
            {renderPagination(filteredTotalPages)}
          </div>
          <div className="flex items-center gap-2 lg:w-32 justify-end w-full">
            <select
              value={limit}
              onChange={(e) => setLimit(Number(e.target.value))}
              className="bg-white dark:bg-bgColor border border-borderBottom text-blue-400 dark:text-[#27FFBA]/60 text-xs rounded px-1 py-1 focus:outline-none focus:border-primary">
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsinManagementModal;
