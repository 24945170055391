import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPriceAlertsWatchlistAPI,
  addSingleAsinAPI,
  deleteSingleAsinAPI,
  exportAsinsAPI,
  searchPriceAlertsWatchlistAPI,
  getPriceAlertInfoAPI,
} from "../../lib/api/api";

export const fetchAsinWatchlist = createAsyncThunk(
  "asinWatchlist/fetchAsinWatchlist",
  async ({ page, limit }, { dispatch }) => {
    const response = await getPriceAlertsWatchlistAPI(page, limit);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const addSingleAsin = createAsyncThunk(
  "asinWatchlist/addSingleAsin",
  async (asin, { dispatch }) => {
    const response = await addSingleAsinAPI(asin);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const deleteSingleAsin = createAsyncThunk(
  "asinWatchlist/deleteSingleAsin",
  async (asin, { dispatch }) => {
    const response = await deleteSingleAsinAPI(asin);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const exportAsins = createAsyncThunk(
  "asinWatchlist/exportAsins",
  async (_, { dispatch }) => {
    const response = await exportAsinsAPI();
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
      return { status };
    }

    // Handle the Blob response by triggering download
    if (response instanceof Blob) {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "asins-export.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }

    return { status: "authorized" };
  }
);

export const searchAsinWatchlist = createAsyncThunk(
  "asinWatchlist/searchAsinWatchlist",
  async (asin, { dispatch }) => {
    const response = await searchPriceAlertsWatchlistAPI(asin);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const fetchPriceAlertInfo = createAsyncThunk(
  "asinWatchlist/fetchPriceAlertInfo",
  async (_, { dispatch }) => {
    const response = await getPriceAlertInfoAPI();
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

const asinWatchlistSlice = createSlice({
  name: "asinWatchlist",
  initialState: {
    watchlist: [],
    status: "idle",
    error: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    authStatus: null,
    asinTokens: 0,
    asinsLeft: 0,
    asinsLocked: 0,
    trackedAsins: 0,
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch watchlist
      .addCase(fetchAsinWatchlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAsinWatchlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.status === "authorized") {
          state.watchlist = action.payload.data || [];
          state.totalPages = action.payload.total_pages || 1;
          state.totalItems = action.payload.total_count || 0;
          state.currentPage = action.payload.current_page || 1;
        }
      })
      .addCase(fetchAsinWatchlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Add single ASIN
      .addCase(addSingleAsin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addSingleAsin.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.status === "authorized") {
          state.totalItems = action.payload.tracked_asins || 0;
          state.currentPage = 1;
          state.totalPages = Math.ceil(state.totalItems / 10);
        }
      })
      .addCase(addSingleAsin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Delete single ASIN
      .addCase(deleteSingleAsin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSingleAsin.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.status === "authorized") {
          state.totalItems = Math.max(0, state.totalItems - 1);
          state.totalPages = Math.ceil(state.totalItems / 10);
        }
      })
      .addCase(deleteSingleAsin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Export ASINs
      .addCase(exportAsins.pending, (state) => {
        state.status = "loading";
      })
      .addCase(exportAsins.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(exportAsins.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Search ASINs
      .addCase(searchAsinWatchlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchAsinWatchlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.status === "authorized") {
          state.watchlist = action.payload.data || [];
          state.totalItems = action.payload.total_count || 0;
          state.totalPages = Math.ceil(state.totalItems / 10);
          state.currentPage = 1;
        }
      })
      .addCase(searchAsinWatchlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch price alert info
      .addCase(fetchPriceAlertInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPriceAlertInfo.fulfilled, (state, action) => {
        if (action.payload?.status === "authorized") {
          state.asinTokens = action.payload.asin_tokens;
          state.asinsLeft = action.payload.asins_left;
          state.asinsLocked = action.payload.asins_locked;
          state.trackedAsins = action.payload.tracked_asins;
        }
      })
      .addCase(fetchPriceAlertInfo.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const { setAuthStatus, setCurrentPage } = asinWatchlistSlice.actions;
export default asinWatchlistSlice.reducer;
