import React, { useContext } from "react";
import { ThemeContext } from "../../context/themeContext";

const FilterKeyword = ({
  item,
  onChange,
  handleBlur,
  handleFocus,
  filterValue,
  preset_name,
  filterModal,
}) => {
  const value = preset_name
    ? filterValue?.["presets"]?.[preset_name] &&
      filterValue?.["presets"]?.[preset_name]?.[item.type]?.value
    : filterValue?.["filter"]?.[item.type]?.value;

  const { theme } = useContext(ThemeContext);

  return (
    <input
      type="text"
      className={`border-[#dcdee2] dark:border-none border-[1px] w-60 h-10 text-center text-[14px] px-2 focus:outline-none   
      rounded p-4 placeholder:text-[12px] placeholder:dark:text-white  placeholder:text-[#5F5E5EE5]  text-title text-transparent`}
      name={item.type}
      onChange={(e) => onChange(e, preset_name)}
      placeholder={item.name}
      value={value || ""}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={{
        background: theme === "light" ? "#fafafa" : "#0f0f12 ",
        color:
          theme === "dark"
            ? filterModal
              ? "white"
              : "white"
            : filterModal
            ? "#5F5E5EE5"
            : "#5F5E5EE5",
        boxShadow: "0px 0px 5px 0px #0000000D",
      }}
    />
  );
};

export default FilterKeyword;
