import axios from "axios";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});
const cookie = "sessionid=abcdef123456";

export const getHomeAPI = async () => {
  try {
    const res = await axiosInstance.get("/home", {
      headers: {
        Cookie: cookie,
      },
    });
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCheckAuthAPI = async () => {
  try {
    const res = await axiosInstance.get("/check_auth", {
      headers: {
        Cookie: cookie,
      },
    });
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getLoginRedirectAPI = async () => {
  try {
    const res = await axiosInstance.get(
      "/login?redirect_uri=" + window.location.origin + "/oauth"
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getAuthCodeAPI = async (token) => {
  try {
    const res = await axiosInstance.get(
      "/oauth?code=" +
        token +
        "&redirect_uri=" +
        window.location.origin +
        "/oauth",
      {
        headers: {},
      }
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getLogoutRedirectAPI = async () => {
  try {
    const res = await axiosInstance.get("/logout");
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getOverviewAPI = async () => {
  try {
    const res = await axiosInstance.get("/overviewData");
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getStoreProductsAPI = async (offSet) => {
  try {
    const res = await axiosInstance.get(
      `/storesearch_products?offset=${offSet}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getSpyProductDetailsAPI = async (asin) => {
  try {
    const res = await axiosInstance.get(`/spy_detail?asin=${asin}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getFilterAPI = async (application) => {
  try {
    const res = await axiosInstance.get(
      `/get_filter?application=${application}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCurrentFilterAPI = async (application) => {
  try {
    const res = await axiosInstance.get(
      `/get_current_filter?application=${application}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const changeFilterAPI = async (
  application,
  type,
  attribute,
  preset_name
) => {
  const apiUrl = preset_name
    ? `/update_filter?application=${application}&type=${type}&attribute=${encodeURIComponent(
        attribute
      )}&preset_name=${preset_name}`
    : `/update_filter?application=${application}&type=${type}&attribute=${encodeURIComponent(
        attribute
      )}`;

  try {
    const res = await axiosInstance.get(apiUrl);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getStoreFilterAPI = async (application) => {
  try {
    const res = await axiosInstance.get(
      `/get_filter?application=${application}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getStoreCurrentFilterAPI = async (application) => {
  try {
    const res = await axiosInstance.get(
      `/get_current_filter?application=${application}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const changeStoreFilterAPI = async (application, type, attribute) => {
  try {
    const res = await axiosInstance.get(
      `/update_filter?application=${application}&type=${type}&attribute=${attribute}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getSpyProductsAPI = async (offSet) => {
  try {
    const res = await axiosInstance.get(`/spy_products?offset=${offSet}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getSellerTrackingAPI = async () => {
  try {
    const res = await axiosInstance.get("/tracked_seller");
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const addSellerTrackingAPI = async (sellername, sellerid) => {
  try {
    const res = await axiosInstance.get(
      `/add_seller?sellername=${sellername}&sellerid=${sellerid}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteSellerTrackingAPI = async (sellerid) => {
  try {
    const res = await axiosInstance.get(`/delete_seller?sellerid=${sellerid}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getA2AProductsAPI = async (productType, offSet) => {
  try {
    const res = await axiosInstance.get(`/${productType}?offset=${offSet}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const priceAlertInfoAPI = async (type) => {
  try {
    const res = await axiosInstance.get(`${type}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const addPriceAlertAPI = async (type, file) => {
  try {
    const res = await axiosInstance.post(`/${type}`, file);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteAllPriceAlertAPI = async (type) => {
  try {
    const res = await axiosInstance.get(`/${type}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getPriceAlertsWatchlistAPI = async (page = 0, limit = 50) => {
  try {
    const res = await axiosInstance.get(
      `/pricealerts_watchlist?page=${page}&limit=${limit}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error fetching watchlist:", error);
    toast.error(error.message);
    return { status: "error", message: error.message };
  }
};

export const addSingleAsinAPI = async (asin) => {
  console.log("asin", typeof asin);
  try {
    const res = await axiosInstance.post(
      "/pricealerts_add_asins",
      {
        asins: [asin],
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Add ASIN response:", res.data);
    return res.data;
  } catch (error) {
    console.error("Error adding ASIN:", error);
    toast.error(error.message);
    return { status: "error", message: error.message };
  }
};

export const deleteSingleAsinAPI = async (asin) => {
  try {
    const res = await axiosInstance.post(
      "/pricealerts_delete_asins",
      {
        asins: [asin],
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Delete ASIN response:", res.data);
    return res.data;
  } catch (error) {
    console.error("Error deleting ASIN:", error);
    toast.error(error.message);
    return { status: "error", message: error.message };
  }
};

export const exportAsinsAPI = async () => {
  try {
    const res = await axiosInstance.get("/export_csv_pricealerts", {
      headers: {
        Accept: "text/csv",
        "Content-Type": "text/csv",
      },
    });

    // If we get an unauthorized response
    if (typeof res.data === "object" && res.data.status === "unauthorized") {
      return res.data;
    }

    // If we get a CSV string, parse it to extract ASINs
    const lines = res.data.split("\n");
    const asins = lines
      .slice(1) // Skip header row
      .map((line) => {
        const parts = line.split(",");
        // Join all parts to handle cases where commas split the ASIN
        return parts.join("").trim();
      })
      .filter((asin) => asin.length > 0); // Remove empty lines

    if (asins.length === 0) {
      toast.warning("No data available to export");
      return { status: "error", message: "No data available to export" };
    }

    // Create a new CSV with proper formatting
    const csvContent = [
      "ASIN", // Header
      ...asins,
    ].join("\n");

    // Create a blob with proper CSV formatting
    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    return blob;
  } catch (error) {
    console.error("Error exporting ASINs:", error);
    toast.error(error.message);
    return { status: "error", message: error.message };
  }
};

export const getPriceAlertInfoAPI = async () => {
  try {
    const res = await axiosInstance.get("/price_alert_info_pricealerts", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    console.log("Price Alert Info response:", res.data);
    return res.data;
  } catch (error) {
    console.error("Error getting price alert info:", error);
    toast.error(error.message);
    return { status: "error", message: error.message };
  }
};

export const searchPriceAlertsWatchlistAPI = async (asin) => {
  try {
    const res = await axiosInstance.get(
      `/pricealerts_watchlist_search?asin=${asin}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error searching price alerts watchlist:", error);
    toast.error(error.message);
    return { status: "error", message: error.message };
  }
};
